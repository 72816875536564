.departures-content {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 21px;
  }
  &_tools {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &_table {
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: auto;
  }
  &_list {
    display: none;
  }
  &_pagination {
    flex-grow: 0;
  }
}

@media (max-width: 1024px) {
  .departures-content {
    &_table {
      display: none;
    }
    &_list {
      display: flex;
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}