.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  border-radius: 30px;
  padding: 0 9px;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.--default {
    background-color: #FBFCFE;
    color: #4B5056;
  }
  &.--success {
    background-color: #E3FBE3;
    color: #1E5B1E;
  }
  &.--danger {
    background-color: #FCE4E4;
    color: #89293A;
  }
}