.progress {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}