.reporting_modal {
  width: 100%;
  height: 100vh;
  &_container {
    width: 100%;
    height: 100%;
    &_form {
      display: flex;
      flex-direction: column;
      width: 900px;
      height: 100%;
      overflow: auto;
      padding: 25px;
      &_field {
        margin-bottom: 60px;
        &_divider {
          margin-top: 10px;
        }
        &_description {
          margin-top: 10px;
        }
      }
      &_button {
        display: flex;
        justify-content: center;
      }
    }
  }
}