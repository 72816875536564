.table {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background-color: #FBFCFE;
  border: 1px solid #CDD7E1;
  overflow: auto;
  &_container {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    table-layout: auto;
  }
  &_header {
    position: sticky;
    top: 0;
    background-color: #F0F4F8;
    border-bottom: 1px solid #CDD7E1;
    z-index: 1000;
    &-cell {
      padding: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #32383E;
      border-bottom: 1px solid #CDD7E1;
    }
  }
  &_body {
    &-row {
      height: auto;
      &:hover {
        background-color: #F0F4F8;
      }
    }
    &-cell {
      width: 150px;
      min-width: 100px;
      max-height: 60px;
      padding: 15px;
      font-size: 12px;
      font-weight: 500;
      white-space: normal;
      word-break: break-word;
      text-overflow: ellipsis;
      color: #556371;
      border-bottom: 1px solid #CDD7E1;
    }
  }
}