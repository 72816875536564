.help_modal {
  padding: 20px;
  &_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }
  &_title {
    display: flex;
    justify-content: center;
  }
  &_button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}