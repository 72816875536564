.helper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 100px;
  padding: 10px;
  width: 350px;
  &_container {
    display: flex;
    align-items: center;
    &_icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }
}