.profile-card {
  width: 100%;
  height: 100%;
  padding: 22px;
  &_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 100%;
  }
  &_main {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }
  &_additional {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}