.departures-tools {
  width: 100%;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 41px;
  }
  &_left-section {
    display: flex;
    flex-grow: 1;
    gap: 15px;
  }
  &_desktop-buttons {
    display: flex;
    flex-shrink: 0;
    gap: 15px;
  }
  &_mobile-buttons {
    display: none;
  }
}

@media (max-width: 1024px) {
  .departures-tools {
    &_mobile-buttons {
      display: flex;
      align-items: center;
      gap: 21px;
    }
    &_desktop-buttons {
      display: none;
    }
  }
}