.details_updates {
  &_container {
    width: 700px;
    &_title {
      margin-bottom: 10px;
    }
    &_content {
      padding: 15px;
      margin-top: 15px;
      border: 1px solid #e0e0e0;
      background-color: #fafafa;
      border-radius: 7px;
      &_date {
        display: flex;
        align-items: center;
        &_title {
          margin-right: 10px;
        }
      }
      &_description {
        margin-top: 15px;
        line-height: 1.5;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
}