.login-banner {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url('https://hotutent.ru/wp-content/uploads/2017/09/news-190917.png') no-repeat center/cover;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}