.additional_information {
  width: 100%;
  height: 100%;
  &_container {
    width: 100%;
    height: 100%;
    &_items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px; 
      &_title {
        display: flex;
        align-items: center;
      }
    }
  }
}

.span {
  font-size: 17px;
  font-weight: 700;
  color: #f44336;
}