.main-navigation {
  width: 100%;
  height: 100%;
  &_container {
    width: 100%;
  }
  &_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    gap: 15px;
  }
  &_link {
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

@media (max-width: 900px) {
  .main-navigation {
    &_row {
      gap: 21px;
    }
    &_link {
      flex: 0 1 calc(50% - 15px);
      max-width: calc(50% - 15px);
    }
  }
}

@media (max-width: 768px) {
  .main-navigation {
    &_row {
      flex-direction: column;
      gap: 21px;
    }
    &_link {
      flex: 0 1 100%;
      max-width: 100%;
    }
  }
}