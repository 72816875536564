.profile-card-description {
  &_container {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  &_branch {
    font-size: 14px;
    font-weight: 500;
  }
}