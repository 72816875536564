.departures-report-create {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 30px;
  }
  &_form {
    flex-grow: 1;
    &-category {
      margin-top: 18px;
      &_title {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: 700;
        gap: 7px;
        margin-bottom: 18px;
      }
      &_attention {
        font-size: 14px;
        font-weight: 700;
      }
      &_items {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }
}