.menu-dropdown {
  padding: 0;
  margin: 0;
  width: 100%;
  cursor: pointer;
  color: #63747D;
  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &_left-section {
    display: flex;
    align-items: center;
  }
  &_right-section {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  &_toggle {
    display: flex;
    align-items: center;
  }
  &_title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    gap: 7px;
  }
  &_chip {
    padding: 1px 10px;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    background-color: #f44336;
    border-radius: 30px;
    z-index: 1;
  }
  &_links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    margin-top: 21px;
  }
  &_link {
    width: 100%;
  }
  &.--active {
    color: #171A1C;
  }
}