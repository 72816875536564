.departures-filters {
  width: 100%;
  &_container {
    width: 100%;
    height: 100%;
    padding: 0 30px;
  }
  &_items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 15px;
  }
}