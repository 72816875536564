.prompt_departure_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 130px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 0 15px;
  transition: background-color, border, color, 0.3s ease;
  &:hover {
    border: 1px solid #4fc3f7;
    background-color: #e1f5fe;
    color: #1976d2;
    cursor: pointer;
  }
  &_container {
    &_description {
      margin-top: 10px;
    }
    &_link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}

.prophylactic_departure_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 130px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 0 15px;
  transition: background-color, border, color, 0.3s ease;
  &:hover {
    border: 1px solid #81c784;
    background-color: #e8f5e9;
    color: #00c853;
    cursor: pointer;
  }
  &_container {
    &_description {
      margin-top: 10px;
    }
    &_link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}

.object_base_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 130px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 0 15px;
  transition: background-color, border, color, 0.3s ease;
  &:hover {
    border: 1px solid #ffb74d;
    background-color: #fff3e0;
    color: #ef6c00;
    cursor: pointer;
  }
  &_container {
    &_description {
      margin-top: 10px;
    }
    &_link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}

.tech_work_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  height: 130px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 0 15px;
  transition: background-color, border, color, 0.3s ease;
  &:hover {
    border: 1px solid #ba68c8;
    background-color: #f3e5f5;
    color: #7b1fa2;
    cursor: pointer;
  }
  &_container {
    &_description {
      margin-top: 10px;
    }
    &_link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .prompt_departure_card {
    width: 240px;
    height: 120px
  }
  .prophylactic_departure_card {
    width: 240px;
    height: 120px;
  }
  .object_base_card {
    width: 240px;
    height: 120px;
  }
  .tech_work_card {
    width: 240px;
    height: 120px;
  }
}

.inactive {
  pointer-events: none;
}


