@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';

*,
*::before,
*::after {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}