.statistic_page {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 600px;
  &_container {
    &_cards {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 30px;
      padding: 15px;
    }
    &_charts {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      padding: 15px;
    }
  }
}