.details-content {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 21px;
  }
  &_tools {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &_columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-grow: 1;
    flex-basis: 0;
    gap: 21px;
    overflow: auto;
  }
  &_item {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #CDD7E1;
    border-radius: 7px;
    background-color: #FBFCFE;
    gap: 15px;
    padding: 15px;
  }
  &_field {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  &_title {
    font-size: 14px;
    font-weight: 500;
    color: #32383E;
  }
  &_value {
    font-size: 12px;
    font-weight: 500;
    color: #556371;
  }
}