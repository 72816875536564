.profile_list {
  display: flex;
  align-items: center;
  width: 130px;
  height: 100px;
  border: 1px solid #9e9e9e;
  color: #424242;
  background-color: #fafafa;
  border-radius: 5px;
  &_container {
    &_item {
      display: flex;
      align-items: center;
      &:first-child {
        margin: 0;
      }
      &:hover {
        cursor: pointer;
        color: #212121;
      }
      margin-top: 18px;
      padding: 0 10px;
      &_icon {
        display: flex;
        margin-right: 10px;
      }
    }
  }
}