.updates {
  width: 100%;
  height: 100%;
  padding: 15px;
  &_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 21px;
  }
}