.details_tools {
  &_container {
    display: flex;
    align-items: center;
    &_title {
      margin-right: 10px;
    }
    &_chip {
      margin-right: 10px;
    }
  }
}