.details_page {
  width: 100%;
  height: 100vh;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    &_breadcrumbs {
      padding: 0;
      margin-top: 20px;
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    &_info {
      display: flex;
      flex-grow: 1;
      width: 100%;
      padding: 20px;
      margin-top: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
      overflow: hidden;
    }
  }
}