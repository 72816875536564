.button_login {
  &_container {
    &_item {
      display: flex;
      align-items: center;
      width: 150px;
      &_icon {
        margin-top: 6px;
        margin-right: 5px;
      }
      &_title {
        text-align: center;
      }
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  gap: 7px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  background-color: #3C50E0;
  color: #fff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #1C2534;
  }
}

.text-button {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 500;
  color: #63747D;
  &.--active {
    color: #171A1C;
  }
  &:hover {
    cursor: pointer;
  }
}

.button_circle {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 100%;
  background-color: #3C50E0;
  color: #fff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #1C2534;
  }
  &:disabled {
    border: 1px solid #9e9e9e;
    background-color: #eeeeee;
    color: #9e9e9e;
    cursor: not-allowed;
  }
  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon_button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}


.button_add {
  &_container {
    &_item {
      display: flex;
      align-items: center;
      width: 150px;
      &_icon {
        margin-top: 6px;
        margin-right: 5px;
      }
      &_title {
        text-align: center;
      }
    }
  }
}

.button_send {
  &_container {
    &_item {
      display: flex;
      align-items: center;
      width: 150px;
      &_icon {
        margin-top: 6px;
        margin-right: 5px;
      }
      &_title {
        text-align: center;
      }
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  //.button {
  //  width: 120px;
  //  height: 36px;
  //  &_title {
  //    font-size: 10px;
  //  }
  //}

  .button_circle {
    width: 50px;
    height: 50px;
  }
}

