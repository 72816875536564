.large_title {
  &_container {
    &_item {
      font-size: 22px;
      font-weight: 700;
    }
  }
}

.middle_title {
  &_container {
    &_item {
      font-size: 14px;
      font-weight: 500;
      color: #171A1C;
    }
  }
}

.common_title {
  &_container {
    &_item {
      font-size: 14px;
    }
  }
}

.white {
  color: #fff;
}

.violet {
  color: #3C50E0;
}

.blue {
  color: #1976d2;
}

.orange {
  color: #ed6c02;
}

.grey {
  color: #32383E;
}

.purple {
  color: #6A7893;
}

.deep_blue {
  color: #1C2534;
}


@media (max-width: 1700px) and (min-width: 1200px) {
  .large_title {
    &_container {
      &_item {
        font-size: 18px;
      }
    }
  }
  .middle_title {
    &_container {
      &_item {
        font-size: 15px;
      }
    }
  }
  .common_title {
    &_container {
      &_item {
        font-size: 13px;
      }
    }
  }
}