.menu_grid {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &_items {
      margin-right: 10px;
      text-decoration: none;
      color: #000;
      &:last-child {
        margin: 0;
      }
    }
  }
}