.details {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &_header {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &_content {
    flex-grow: 1;
    margin-top: 30px;
  }
}