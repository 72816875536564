.profile-footer {
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  &_container {
    width: 100%;
    height: 100%;
  }
  &_logout {
    width: 100%;
  }
}