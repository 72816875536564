.departures-header {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &_title {
    display: flex;
    align-items: center;
  }
  &_button {
    width: 130px;
  }
}

@media (max-width: 1024px) {
  .departures-header {
    &_container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}