.statistic-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 150px);
  overflow: hidden;
  &_container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    gap: 15px;
    padding: 0 10px;
    overflow-y: auto;
  }
}