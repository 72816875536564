.header {
  width: 100%;
  flex-shrink: 0;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 21px;
  }
  &_burger {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header {
    &_burger {
      display: block;
    }
  }
}