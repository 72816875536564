.quantity {
  height: 600px;
  overflow: auto;
  border-radius: 0;
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .quantity {
    height: 560px;
  }
}

.vertical_text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
}