.filter {
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  border: 1px solid #CDD7E1;
  background-color: #FBFCFE;
  border-radius: 5px;
  &_label {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 0 15px;
    font-size: 14px;
    user-select: none;
  }
  &_badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    background-color: #3C50E0;
    color: #fff;
  }
  &_menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 2px);
    z-index: 1000;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 5px;
    user-select: none;
    &:active {
      transition: 0.6s ease;
    }
  }
  &_item {
    padding: 10px 15px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: pre-wrap;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #F0F4F8;
    }
    &.selected {
      background-color: #E3FBE3;
    }
  }
}

.date-range-filter {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  &_item {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  &_label {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    font-weight: 500;
  }
  &_input {
    height: 45px;
    padding: 0 15px;
    border: 1px solid #CDD7E1;
    border-radius: 7px;
    background-color: #FBFCFE;
  }
}