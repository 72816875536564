.departure_page {
  width: 100%;
  height: 100vh;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    &_breadcrumbs {
      margin-top: 20px;
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    &_search {
      margin-top: 10px;
    }
    &_table {
      margin-top: 20px;
    }
    &_pagination {
      margin-top: 20px;
    }
  }
}