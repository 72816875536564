.statistic-card {
  flex: 1 1 calc(50% - 15px);
  height: 500px;
  border: 1px solid #CDD7E1;
  border-radius: 7px;
  background-color: #FBFCFE;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 21px;
    padding: 15px;
  }
  &_title {
    display: flex;
    font-size: 18px;
    font-weight: 500;
  }
  &_chart {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.pie {
    flex: 0.4 1 calc(35% - 15px);
  }

  &.bar {
    flex: 0.6  1 calc(65% - 15px);
  }

  .statistic-content_container > .statistic-card.bar:only-child,
  .statistic-content_container > .statistic-card.bar:nth-last-child(2):first-child {
    flex: 1 1 100%;
  }

  .statistic-content_container > .statistic-card.bar:nth-child(odd):not(:last-child) + .statistic-card.bar {
    flex: 1 1 calc(50% - 15px);
  }
}