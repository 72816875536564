.password {
  display: flex;
  align-items: center;
  position: relative;
  &_input {
    flex: 1;
    height: 45px;
    padding: 15px;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 5px;
    outline: none;
  }
  &_button {
    position: absolute;
    right: 10px;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.email {
  display: flex;
  align-items: center;
  &_input {
    flex: 1;
    height: 45px;
    padding: 15px;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 5px;
    outline: none;
  }
}


.input {
  width: 100%;
  height: 45px;
  border: 1px solid #CDD7E1;
  background-color: #FBFCFE;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 15px;
  outline: none;
  user-select: none;
  cursor: pointer;
}

.textarea {
  width: 100%;
  height: 300px;
  resize: none;
  border: 1px solid #CDD7E1;
  background-color: #FBFCFE;
  border-radius: 5px;
  font-size: 14px;
  padding: 15px;
  outline: none;
  user-select: none;
  cursor: pointer;
}


.password-input {
  &_container {
    &_item {
      width: 300px;
    }
  }
}

.login_input {
  &_container {
    &_item {
      width: 300px;
    }
  }
}

.reporting_date_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.reporting_person_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.starting_date_input {
  &_container {
    width: 350px;
  }
}

.finish_date_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.emergency_information_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.minor_injury_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.severe_injury_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.fatal_injury_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.evacuated_people_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.healing_people_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.work_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.situation_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.other_technique_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.workers_count_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.human_hours_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.other_organizations_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.other_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.type_input {
  &_container {
      &_item {
        width: 350px;
      }
    }
  }

.location_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}

.owner_input {
  &_container {
    &_item {
      width: 350px;
    }
  }
}