.indicator-manager {
  border: none;
  background: none;
  &.--failure {
    color: #f44336;
  }
  &.--success {
    color: #4caf50;
  }
  &.--warning {
    color: #ff9800;
  }
  &.--default {
    color: #9e9e9e;
  }
}