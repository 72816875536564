.menu-link {
  color: #63747D;
  &_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    gap: 7px;
  }
  &_chip {
    padding: 1px 10px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    background-color: #f44336;
    border-radius: 30px;
    z-index: 1;
  }
  &.--active {
    color: #171A1C;
  }
  &.--hide {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}