.password_modal {
  padding: 20px;
  &_container {
    &_title {
      display: flex;
      justify-content: center;
    }
    &_items {
      &_input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
      }
    }
    &_button {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
}