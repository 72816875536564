.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &_container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    background-color: #fff;
    padding: 0 30px;
  }
  &_banner {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .auth {
    flex-direction: column;

    &_login {
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 2;
      padding: 0 20px;
    }

    &_banner {
      position: absolute;
      z-index: 1;
    }
  }
}