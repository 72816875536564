.help-modal {
  width: 100%;
  height: 100%;
  padding: 15px;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 21px;
  }
  &_title {
    display: flex;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 22px;
    font-weight: 700;
  }
  &_form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 21px;
  }
  &_button {
    flex-grow: 0;
    flex-shrink: 0;
  }
}