.emergency_name_dropdown {
  &_container {
    &_select {
      width: 350px;
    }
  }
}

.filter {
  position: relative;
  display: flex;
  width: 100%;
  height: 35px;
  border: 1px solid #CDD7E1;
  background-color: #FBFCFE;
  border-radius: 7px;
  &_label {
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    user-select: none;
  }
  &_menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 2px);
    z-index: 10000;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 7px;
    user-select: none;
    &_item {
      padding: 10px 15px;
      font-size: 14px;
      &.--selected {
        background-color: #E3FBE3;
      }
    }
  }
}

.multi-select-dropdown {
  width: 100%;
  position: relative;
  &_container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 7px;
    padding: 0 15px;
  }
  &_label {
    flex: 1;
    font-size: 14px;
  }
  &_menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 2px);
    left: 0;
    z-index: 10000;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 7px;
  }
  &_item {
    padding: 10px 15px;
    font-size: 14px;
    &:hover {
      background-color: #F0F4F8;
    }
  }
  &_selected-options {
    display: flex;
    flex-direction: column;
    gap: 21px;
    margin-top: 21px;
    &_wrapper {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    &_title {
      font-size: 14px;
      font-weight: 500;
    }
    &_item {
      display: flex;
      align-items: center;
      gap: 7px;
    }
    &_input {
      flex: 1;
      min-height: 35px;
      height: 45px;
      border: 1px solid #CDD7E1;
      background-color: #FBFCFE;
      border-radius: 7px;
      padding: 0 10px;
      font-size: 14px;
    }
    &_button {
      border: none;
      background: none;
    }
  }
}

.active {
  background-color: #f1f8e9;
}

.create-fields {
  width: 100%;
  &_container {
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;
  }
  &_input {
    flex: 1;
    min-height: 35px;
    height: 45px;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 7px;
    padding: 0 10px;
    font-size: 14px;
  }
  &_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 21px;
    margin-top: 21px;
    &_group {
      display: flex;
      flex-direction: column;
      gap: 21px;
    }
    &_field {
      display: flex;
      align-items: center;
      gap: 7px;
    }
    &_input {
      flex: 1;
      min-height: 45px;
      border: 1px solid #CDD7E1;
      background-color: #FBFCFE;
      border-radius: 7px;
      padding: 0 10px;
      font-size: 14px;
    }
  }
}

.dropdown-with-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35px;
  position: relative;
  border-radius: 7px;
  border: 1px solid #CDD7E1;
  background-color: #FBFCFE;
  gap: 21px;
  &_label {
    display: flex;
    align-items: center;
    padding: 6px 15px;
    font-size: 14px;
    cursor: pointer;
  }
  &_menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 2px);
    padding: 0;
    margin: 0;
    border-radius: 7px;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    z-index: 1000;
  }
  &_item {
    padding: 7px;
    font-size: 14px;
  }
  &_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
  }
  &_row {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  &_name {
    font-size: 14px;
    font-weight: 500;
  }
  &_input {
    height: 35px;
    border-radius: 7px;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    padding: 0 15px;
  }
  &_button {
    background: none;
    border: none;
  }
}

.dropdown {
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  border: 1px solid #CDD7E1;
  background-color: #FBFCFE;
  border-radius: 5px;
  &_label {
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    user-select: none;
  }
  &_menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 2px);
    z-index: 1000;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #CDD7E1;
    background-color: #FBFCFE;
    border-radius: 5px;
    user-select: none;
    &:active {
      transition: 0.6s ease;
    }
    &_item {
      padding: 10px 15px;
      font-size: 14px;
      word-wrap: break-word;
      white-space: pre-wrap;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #F0F4F8;
      }
    }
  }
}

.type_emergency_dropdown {
  &_container {
    &_select {
      width: 350px;
    }
  }
}

.object_owner_dropdown {
  &_container {
    &_select {
      width: 350px;
    }
  }
}

.transport_dropdown {
  &_container {
    &_select {
      width: 350px;
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .filter {
    &_label {
      font-size: 13px;
    }
    &_menu {
      &_item {
        font-size: 13px;
      }
    }
  }
}