.export-modal {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 15px;
  }
  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    font-size: 21px;
    border-radius: 50%;
    background-color: #E3FBE3;
    color: #1E5B1E;
  }
  &_title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
  }
  &_progress {
    width: 100%;
  }
  &_button {
    width: 150px;
  }
}