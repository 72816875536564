.reports_filters {
  &_container {
    display: flex;
    align-items: center;
    &_icon {
      margin-right: 10px;
      color: #1976d2;
    }
    &_filter {
      margin-right: 10px;
    }
  }
}