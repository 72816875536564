.modal_expired {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  &_container {
    background-color: #fff;
    padding: 20px;
    width: 700px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    &_message {
      margin-top: 15px;
    }
    &_button {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}