.list {
  width: 100%;
  height: 100%;
  overflow: auto;
  &_container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &_item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border-bottom: 1px solid #CDD7E1;
  }
  &_title {
    font-size: 14px;
    font-weight: 500;
    color: #32383E;
  }
  &_value {
    font-size: 14px;
    font-weight: 500;
    color: #556371;
  }
  &_header {
    font-size: 18px;
    font-weight: 500;
  }
}