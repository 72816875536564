.timeline {
  width: 100%;
  height: 100%;

  &_container {
    position: relative;
    width: 100%;
  }

  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_item {
    display: flex;
    align-items: stretch;
    position: relative;
  }

  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 40px;
    position: relative;
  }

  &_dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #3C50E0;
    position: relative;
  }

  &_line {
    width: 2px;
    height: 100%;
    background-color: #3C50E0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &_right {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  &_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #CDD7E1;
    border-radius: 7px;
    gap: 10px;
    padding: 10px;
    margin-bottom: 10px;
    word-break: break-word;
  }

  &_field {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  &_title {
    font-size: 14px;
    font-weight: 500;
    color: #32383E;
  }

  &_text {
    font-size: 12px;
    font-weight: 500;
    color: #556371;
  }

  &_user {
    display: flex;
    justify-content: flex-end;
  }
}