.navigation-card {
  position: relative;
  flex-grow: 1;
  min-width: 200px;
  height: 40px;
  border-radius: 30px;
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 7px;
    gap: 7px;
  }
  &_chip {
    position: absolute;
    bottom: 29px;
    right: 15px;
    padding: 1px 10px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background-color: #f44336;
    border-radius: 30px;
    z-index: 1;
  }
  &_icon {
    display: flex;
    align-items: center;
  }
  &_title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
  }
}