.pagination {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 10px;
    border: 1px solid #CDD7E1;
    border-radius: 7px;
    background-color: #FBFCFE;
    &:hover {
      background-color: #F0F4F8;
      cursor: pointer;
    }
    &.--disabled {
      color: #63747D;
    }
  }
  &_page {
    font-size: 14px;
    font-weight: 500;
  }
}