.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  &_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 15px;
    border-radius: 7px;
    background: #fff;
    animation: fadeIn 0.3s ease-in-out;
  }
  &_close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}