.field {
  &_container {
    &_items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      &_title {
        display: flex;
        align-items: center;
      }
    }
  }
}