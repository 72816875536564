.main_page {
  width: 100%;
  height: 100vh;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &_profile {
      display: flex;
      justify-content: flex-end;
    }
    &_logo {
      display: flex;
      justify-content: center;
    }
    &_title {
      text-align: center;
      margin-top: 30px;
    }
    &_menu {
      margin-top: 30px;
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .main_page {
    &_container {
      &_title {
        margin-top: 15px;
      }
      &_menu {
        margin-top: 20px;
      }
    }
  }
}