.menu {
  width: 270px;
  height: 100vh;
  background-color: #FBFCFE;
  border-right: 1px solid #CDD7E1;
  color: #1C2534;
  transition: transform 0.3s ease-in-out;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 21px;
    padding: 21px;
  }
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
  }
  &_close {
    display: none;
  }
  &_main {
    flex: 1 0 auto;
  }
}

@media (max-width: 1024px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    z-index: 1000;
    &.--open {
      transform: translateX(0);
    }
    &_close {
      display: block;
    }
  }
}
