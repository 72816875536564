@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.alert_success {
  &_container {
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 1000;
  }
}

.alert_info {
  &_container {
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 1000;
  }
}

.alert_warning {
  &_container {
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 1000;
  }
}

.alert_error {
  &_container {
    max-width: 500px;
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 1000;
  }
}

.alert_enter {
  animation: slideIn 0.5s ease-out forwards;
}

.alert_exit {
  animation: slideOut 0.5s ease-in forwards;
}