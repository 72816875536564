.report_page {
  width: 100%;
  height: 100vh;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    &_breadcrumbs {
      padding: 0;
      margin-top: 20px;
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      &_button {
        display: flex;
        gap: 10px;
      }
    }
    &_search {
      margin-top: 10px;
    }
    &_table {
      width: 100%;
      margin-top: 20px;
    }
    &_pagination {
      margin-top: 20px;
    }
  }
}