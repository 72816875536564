.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  &_container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 750px;
    height: 100%;
    background-color: #FBFCFE;
    border-left: 1px solid #CDD7E1;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #CDD7E1;
  }
  &_content {
    flex-grow: 1;
    width: 100%;
    overflow: auto;
  }
  &_footer {
    flex-grow: 0;
    flex-shrink: 0;
    border-top: 1px solid #CDD7E1;
  }
}