.profile-card-name {
  &_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      font-size: 22px;
      font-weight: 500;
    }
    &_email {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
    }
  }
}