.profile {
  &_container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 7px;
  }
  &_avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50%;
    background-color: #35baf6;
  }
  &_name {
    font-size: 14px;
    font-weight: 700;
    color: #32383E;
  }
}

@media (max-width: 768px) {
  .profile {
    &_name {
      display: none;
    }
  }
}