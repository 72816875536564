.main-content {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 30px;
    padding: 15px;
  }
  &_navigation {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}