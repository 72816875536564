.search-bar {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 10px;
  &_wrapper {
    position: relative;
    flex-grow: 1;
  }
  &_input {
    width: 100%;
    height: 35px;
    border: 1px solid #CDD7E1;
    border-radius: 7px;
    background-color: #FBFCFE;
    outline: none;
    padding: 15px;
  }
  &_clear {
    position: absolute;
    right: 10px;
    top: 19px;
    transform: translateY(-50%);
    border: none;
    background: none;
    font-size: 18px;
    color: #9e9e9e;
    transition: color 0.2s;
  }
}