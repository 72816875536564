.profile-card-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 210px;
  font-size: 70px;
  font-weight: 700;
  border-radius: 50%;
  background-color: #35baf6;
}