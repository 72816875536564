.auth-form {
  width: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 100%;
  }
  &_fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 15px;
  }
  &_button {
    width: 100%;
    max-width: 400px;
  }
}