.progress-bar {
  width: 100%;
  height: 15px;
  border-radius: 30px;
  background-color: #eeeeee;
  &_item {
    height: 100%;
    border-radius: 30px;
    background-color: #3C50E0;
  }
}