.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.blue_circle {
  background-color: #35baf6;
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .avatar {
    width: 27px;
    height: 27px;
  }
}