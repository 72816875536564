.main-header {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }
  &_logo {
    display: flex;
    align-items: center;
  }
}