.details-tools {
  width: 100%;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_number {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    gap: 15px;
  }
  &_buttons {
    display: flex;
    gap: 15px;
  }
}