.total_card {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 335px;
  width: 100%;
  height: 100px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  &_container {
    &_description {
      margin-top: 10px;
    }
  }
}