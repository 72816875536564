.alert {
  position: fixed;
  max-width: 600px;
  top: 20px;
  left: 20px;
  z-index: 10000;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 7px;
  color: #fff;
  font-size: 14px;
  animation: slideIn 0.5s ease, fadeOut 0.5s ease 14.5s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.success {
  color: #1b5e20;
  border: #1b5e20 1px solid;
  background-color: #e8f5e9;
}

.warning {
  color: #e65100;
  border: 1px solid #e65100;
  background-color: #fff3e0;
}

.info {
  color: #0d47a1;
  border: 1px solid #0d47a1;
  background-color: #e3f2fd;
}

.failure {
  color: #b71c1c;
  border: 1px solid #b71c1c;
  background-color: #ffebee;
}