.layout {
  height: 100vh;
  &_container {
    display: flex;
  }
  &_menu {
    flex-shrink: 0;
  }
  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &_header {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &_main {
    flex-grow: 1;
    overflow: auto;
    padding: 21px;
  }
}
