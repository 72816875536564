.breadcrumbs {
  &_container {
    display: flex;
    gap: 10px;
    color: #1976d2;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    &_list {
      display: inline;
      &:after {
        content: '>';
        margin-left: 5px;
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
      &_link {
        color: #3C50E0;
        text-decoration: none;
      }
    }
  }
}

.current_link {
  color: #757575;
  &:hover {
    cursor: default;
  }
}

@media (max-width: 1700px) and (min-width: 1200px) {
  .breadcrumbs {
    &_container {
      font-size: 13px;
    }
  }
}