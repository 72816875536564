.main_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #1976d2;
    border: 1px solid #1976d2;
    background-color: #bbdefb;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.first_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #e91e63;
    border: 1px solid #e91e63;
    background-color: #fce4ec;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.second_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #ff6f00;
    border: 1px solid #ff6f00;
    background-color: #fff8e1;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.third_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #f4511e;
    border: 1px solid #f4511e;
    background-color: #fbe9e7;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.fourth_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #33691e;
    border: 1px solid #33691e;
    background-color: #f1f8e9;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.fifth_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #9c27b0;
    border: 1px solid #9c27b0;
    background-color: #f3e5f5;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.sixth_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #b71c1c;
    border: 1px solid #b71c1c;
    background-color: #ffcdd2;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.seventh_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #673ab7;
    border: 1px solid #673ab7;
    background-color: #ede7f6;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.eighth_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #3f51b5;
    border: 1px solid #3f51b5;
    background-color: #e8eaf6;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.ninth_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #01579b;
    border: 1px solid #01579b;
    background-color: #e1f5fe;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.tenth_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #f44336;
    border: 1px solid #f44336;
    background-color: #ffebee;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.eleventh_branch {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #006064;
    border: 1px solid #006064;
    background-color: #e0f7fa;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}