 .details {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  &_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &_item {
      &:first-child {
        margin: 0;
      }
      margin-top: 30px;
    }
    &_buttons {
      position: fixed;
      bottom: 20px;
      right: 90px;
      z-index: 1000;
      &_print {
        margin-top: 10px;
      }
    }
  }
}