.details-header {
  width: 100%;
  height: 100%;
  &_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &_title {
    display: flex;
    align-items: center;
  }
  &_button {
    width: 130px;
  }
}