.filter-field {
  width: 100%;
  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px;
  }
  &_header {
    display: flex;
    gap: 7px;
  }
  &_label {
    font-size: 14px;
    font-weight: 500;
  }
}