.company-name {
  &_container {
    display: flex;
    align-items: center;
    gap: 7px;
    &_avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 700;
      background-color: #35baf6;
    }
    &_title {
      font-size: 14px;
      font-weight: 500;
      color: #32383E;
    }
  }
}