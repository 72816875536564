.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  &_header {
    width: 100%;
    flex: 0 0 auto;
  }
  &_content {
    flex: 1 0 auto;
  }
}