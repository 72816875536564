.pagination {
  width: 100%;
  height: 100%;
  user-select: none;
  &_container {
    width: 100%;
    height: 100%;
    &_items {
      display: flex;
      justify-content: space-between;
      &_back {
        display: flex;
        align-items: center;
        transition: color 0.3s ease;
        &:hover {
          color: #1976d2;
          cursor: pointer;
        }
      }
      &_current {
        display: flex;
        align-items: center;
      }
      &_next {
        display: flex;
        align-items: center;
        transition: color 0.3s ease;
        &:hover {
          color: #1976d2;
          cursor: pointer;
        }
      }
    }
  }
}