.profile_menu {
  position: relative;
  user-select: none;
  &_container {
    width: 100%;
    height: 100%;
    padding: 10px;
    &_item {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &_avatar {
        margin-right: 5px;
      }
      &_icon {
        display: flex;
      }
    }
    &_list {
      position: absolute;
      top: 100%;
      right: 7px;
      z-index: 1000;
    }
  }
}