.success_chip {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #1b5e20;
    border: 1px solid #1b5e20;
    background-color: #e8f5e9;
    border-radius: 100px;
    &_title {
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.process_chip {
  &_container {
    width: 130px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #d32f2f;
    border: 1px solid #d32f2f;
    background-color: #ffebee;
    border-radius: 100px;
    &_title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }
}