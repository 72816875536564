.created_date {
  &_container {
    width: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #ff6f00;
    border: 1px solid #ff6f00;
    background-color: #fff8e1;
    border-radius: 100px;
    &_title {
      text-align: center;
    }
  }
}

.start_date {
  &_container {
    width: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #d32f2f;
    border: 1px solid #d32f2f;
    background-color: #ffebee;
    border-radius: 100px;
    &_title {
      text-align: center;
    }
  }
}

.finish_date {
  &_container {
    width: 140px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #1b5e20;
    border: 1px solid #1b5e20;
    background-color: #e8f5e9;
    border-radius: 100px;
    &_title {
      text-align: center;
    }
  }
}

.update_date {
  &_container {
    width: 130px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    color: #1976d2;
    border: 1px solid #1976d2;
    background-color: #e3f2fd;
    border-radius: 100px;
    &_title {
      text-align: center;
    }
  }
}