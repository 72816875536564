.departures_filters {
  display: flex;
  padding: 0;
  margin: 0;
  &_container {
    display: flex;
    align-items: center;
    &_icon {
      margin-right: 10px;
      color: #1976d2;
    }
    &_filter {
      margin-right: 10px;
    }
  }
}