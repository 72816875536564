.details_item {
  width: 700px;
  &_container {
    &_description {
      padding: 15px;
      margin-top: 15px;
      border: 1px solid #e0e0e0;
      background-color: #fafafa;
      border-radius: 7px;
    }
  }
}